import React from "react";
import { graphql } from "gatsby";
import GenericPostPageWrapper, { GenericPostTemplate } from "./generic/genericPostTemplate";

export const BlogPostTemplate = ({ page }) =>
	<GenericPostTemplate page={page} siteTitle="PREVIEW" />;

export default GenericPostPageWrapper({
	type: "מאמרים",
	sectionPath: "/blog",
	showSimilar: true,
	showInlineAd: true,
});

export const pageQuery = graphql`
  query BlogPostByIdQuery($id: String!, $tags: [String]) {
    ...SiteTitle
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        rawDate: date
        title
        description
        descriptionRaw
        tags
        author
        templateKey
				postId
      }
      fields {
        slug
      }
      ...RemoteBannerImage
	}
	similar: allMarkdownRemark(
			sort: {order: [DESC], fields: [frontmatter___date]},
			filter: {frontmatter: {templateKey: {eq: "blog-post"}, tags: {in: $tags}}},
			limit: 4) {
		edges {
			...FeaturedContent
		}
	}
	similarProducts: allMarkdownRemark(
      sort: {order: [DESC], fields: [frontmatter___date]},
      filter: {frontmatter: { allSaleIndex: { eq: true }, tags: {in: $tags} } },
      limit: 4
  ) {
      edges {
          ...AllSaleItem
      }
	}
	recent: allMarkdownRemark(sort: {order: [DESC, DESC], fields: [frontmatter___featuredpost, frontmatter___date]}, filter: {frontmatter: {templateKey: {eq: "blog-post"}}}, limit: 3) {
		edges {
			...FeaturedContent
		}
	}
  }`;
